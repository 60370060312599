<!-- 视频播放的组件 -->
<template>
  <div class="video-list">
    <!-- 视频播放组件 -->
    <div class="player">
      <VideoPlayer></VideoPlayer>
    </div>
  </div>
</template>

<script>
// 引入视频播放组件
import VideoPlayer from "@/components/videoplayer/VideoPlayer";
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  components: {
    VideoPlayer,
  },
};
</script>

<style lang="scss" scoped>
.video-list {
  height: calc(100vh - 77px);
  display: flex;
  align-items: center;
  justify-content: center;
  .player {
    width: 1000px;
  }
}
</style>
