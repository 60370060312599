import { render, staticRenderFns } from "./VideoPlayer.vue?vue&type=template&id=453e5417&scoped=true&"
import script from "./VideoPlayer.vue?vue&type=script&lang=js&"
export * from "./VideoPlayer.vue?vue&type=script&lang=js&"
import style0 from "./VideoPlayer.vue?vue&type=style&index=0&id=453e5417&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "453e5417",
  null
  
)

export default component.exports